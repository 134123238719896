import React from "react"

import Seo from "../components/Seo"
import Layout from "../components/Layout"

import { graphql, Link } from "gatsby"

import Grid from "../components/Grid"
import SafeAreaWrapper from "../components/SafeAreaWrapper"
import Container from "../components/Container"
import Hero from "../components/Hero"
import { Button } from "../components/Button"

import { BlueBanner } from "../components/Banner"

import { LocationMarker, Phone } from "../components/Icon/"

import { getImage, GatsbyImage } from "gatsby-plugin-image"

const ContactPage = ({
	data: {
		page,
		backgroundImage,
		locationHamburgImage,
		locationDenmarkImage,
		locationSingaporeImage,
		locationLondonImage,
		locationGreeceImage,
	},
}) => {
	const officeLocations = {
		type: "FeatureCollection",
		features: [
			{
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [10.00714, 53.58547],
				},
				properties: {
					id: 1,
					className: "xl:order-1",
					state: "FLY_TO_HAMBURG",
					onMouseOverState: "CENTER_ON_HAMBURG",
					title: "Main Office Hamburg",
					address: "Hanseaticsoft GmbH<br />Poßmoorweg 2<br />22301 Hamburg<br />Germany",
					phone: "+49 40 349 700 10 210",
					image: getImage(locationHamburgImage),
				},
			},
			{
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [12.57554, 55.73346],
				},
				properties: {
					id: 2,
					className: "xl:order-1",
					state: "FLY_TO_DENMARK",
					onMouseOverState: "CENTER_ON_DENMARK",
					title: "Branch Office Denmark",
					address:
						"Lloyd's Register<br />Harbour House 2<br />Sundkrogsgade 19, 3rd Floor<br />2100 Copenhagen<br />Denmark",
					image: getImage(locationDenmarkImage),
					phone: "+45 6155 2982",
				},
			},
			{
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [103.78448, 1.29113],
				},
				properties: {
					id: 3,
					className: "xl:order-3",
					state: "FLY_TO_SINGAPORE",
					onMouseOverState: "CENTER_ON_SINGAPORE",
					title: "Branch Office Singapore",
					address:
						"Lloyd's Register<br />9 North Buona Vista Drive<br />#02-01, Tower 1 The Metropolis<br />Singapore 138588",
					image: getImage(locationSingaporeImage),
				},
			},
			{
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [-0.07994, 51.51218],
				},
				properties: {
					id: 4,
					className: "xl:order-3",
					state: "FLY_TO_LONDON",
					onMouseOverState: "CENTER_ON_LONDON",
					title: "Branch Office London",
					address:
						"Lloyd's Register<br />71 Fenchurch St<br />London EC3M 4BS<br />United Kingdom",
					image: getImage(locationLondonImage),
				},
			},
			{
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [-0.07994, 51.51218],
				},
				properties: {
					id: 4,
					className: "xl:order-3",
					state: "FLY_TO_GREECE",
					onMouseOverState: "CENTER_ON_GREECE",
					title: "Branch Office Greece",
					address:
						"Hellenic Lloyd's S.A.<br />348 Syggrou Avenue<br />17674, Kallithea, Athens<br />Greece",
					image: getImage(locationGreeceImage),
				},
			},
		],
	}

	return (
		<Layout translucent={true}>
			<Seo
				title={page.seo.title}
				description={page.seo.description}
				keywords={page.seo.keywords}
			/>
			<Hero
				title="Say hello to us"
				subtitle="Let's have a coffee or two"
				backgroundImage={backgroundImage}
				translucent={true}
			/>
			<div className="py-spacer-xl">
				<SafeAreaWrapper>
					<Grid className="gap-y-40">
						{officeLocations.features.map(
							({
								properties: {
									id,
									title,
									address,
									image,
									phone,
									className,
									state,
									onMouseOverState,
								},
							}) => (
								<div
									key={id}
									className={`col-span-12 md:col-span-6 xl:col-span-4 ${className}`}>
									<div className={`flex flex-col items-start rounded-medium`}>
										<GatsbyImage
											image={image}
											alt={title}
											title={title}
											className="w-full h-[220px] rounded-medium flex-none"
										/>
										<div className="grow xl:mx-20">
											<h3 className="display3 mt-20 mb-[14px]">{title}</h3>
											<div className="flex flex-row">
												<span className="mr-6 pt-4">
													<LocationMarker />
												</span>
												<p
													className="psmall"
													dangerouslySetInnerHTML={{ __html: address }}
												/>
											</div>
											{phone && (
												<div className="flex flex-row mt-10">
													<span className="mr-6 self-center">
														<Phone />
													</span>
													<p className="psmall">
														<a
															href={`tel:${phone}`}
															className="hover:text-pictonblue">
															{phone}
														</a>
													</p>
												</div>
											)}
										</div>
									</div>
								</div>
							),
						)}
					</Grid>

					<Container className="my-spacer-md">
						<div className="bg-porcelain rounded-large py-60 flex flex-col items-center justify-center">
							<span className="text-pictonblue captionBtn text-center">Support</span>
							<h2 className="text-black mt-12 mb-30 text-center display3">
								You need help with your Cloud Fleet Manager applications?
							</h2>

							<div className="text-center">
								<Link to="/support">
									<Button primary={false} label="Contact support" />
								</Link>
							</div>
						</div>
					</Container>

					<BlueBanner
						className="my-spacer-xl"
						title={page.banner.title}
						subtitle={page.banner.pretext}
						image={
							page.banner.screenshot
								? getImage(page.banner.screenshot.localFile)
								: null
						}
						callToAction="Schedule a demo"
						callToActionUrl="/demo"
					/>
				</SafeAreaWrapper>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		page: strapiContactPage {
			seo {
				title
				description
				keywords
			}

			banner {
				pretext
				title
				screenshot {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 760)
						}
					}
				}
			}
		}
		backgroundImage: file(relativePath: { eq: "contact.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
			}
		}

		locationHamburgImage: file(relativePath: { eq: "office-hamburg.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, height: 240, placeholder: DOMINANT_COLOR)
			}
		}
		locationDenmarkImage: file(relativePath: { eq: "office-denmark.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, height: 240, placeholder: DOMINANT_COLOR)
			}
		}
		locationSingaporeImage: file(relativePath: { eq: "office-singapore.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, height: 240, placeholder: DOMINANT_COLOR)
			}
		}
		locationLondonImage: file(relativePath: { eq: "office-london.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, height: 240, placeholder: DOMINANT_COLOR)
			}
		}
		locationGreeceImage: file(relativePath: { eq: "office-greece.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, height: 240, placeholder: DOMINANT_COLOR)
			}
		}
	}
`

export default ContactPage
